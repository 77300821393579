export default class Faq {
  constructor (el) {
    this.el = el
    this.items = this.el.querySelectorAll('.js-faq-item')
  }

  init () {
    this.initEvents()
    this.initDefaultState()
  }

  initEvents () {
    this.items.forEach(el => {
      el.addEventListener('click', e => this.toggle(e))
    });
  }

  toggle (e) {
    e.preventDefault()
    const content = e.currentTarget.querySelector('.faq__content')
    $(content).slideToggle(300)
    e.currentTarget.classList.toggle('active')
  }

  initDefaultState () {
    this.items.forEach(el => {
      const content = el.querySelector('.faq__content')
      el.classList.contains('active') ? content.style.display = 'block' : content.style.display = 'none'
    })
  }
}
