import Inputmask from "inputmask"

export default class Input {

  constructor (el) {
    this.el = el
    this.input = this.el.querySelector('input')
    this.im = null
  }

  init () {
    this.initEvents()
    if (this.input.value) {
      this.el.classList.add('focus')
    }

    if (this.input.getAttribute('type') === 'tel') {
      this.im = new Inputmask(
        {
          mask: "+38-999-999-99-99",
          showMaskOnHover: false
        }
      );
      this.im.mask(this.input);
    }
  }

  initEvents () {
    this.input.addEventListener('focus', () => this.onFocus())
    this.input.addEventListener('blur', () => this.onBlur())
  }

  onFocus () {
    this.el.classList.add('focus')
  }

  onBlur () {
    if (this.input.value === '') {
      this.el.classList.remove('focus')
    }
  }

}
