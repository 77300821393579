// ----- import components

import Header from "../blocks/modules/header/header"
import TextSlider from "../blocks/components/text-slider/text-slider"
import ServicesSlider from "../blocks/components/services-slider/services-slider"
import StepsSlider from "../blocks/components/steps-slider/steps-slider"
import Faq from "../blocks/components/faq/faq"
import FSlider from "../blocks/components/f-slider/f-slider"
import Input from "../blocks/components/input/input"
import FPlate from "../blocks/components/f-plate/f-plate"
import ScrollAnimate from "./global/scroll-animate"

// ----- import functions

import { scrollToId } from "./global/scrollToId"

// ----- env

import { BASE_URL } from '../../env-config'

// ----- components instances

let header = null
let textSlider = []
let servicesSlider = []
let stepsSlider = []
let faq = []
let fSlider = []
let inputs = []
let fplates = []
let scrollAnimate = null

// ----- flags

let isMobile = false

function checkMobile () {
  isMobile = /iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/.test(navigator.userAgent)

  if(!isMobile && navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel|iPad/.test(navigator.platform)) {
    isMobile = true
  }

  if (!isMobile && window.innerWidth < 1025) {
    isMobile = true
  }

  if (isMobile) {
    document.body.classList.add('mobile')
  }
}

// ----- events

document.addEventListener('DOMContentLoaded', () => {
  init()
})

window.addEventListener('resize', () => {
  onResize()
})

// ----- init components

function init () {

  floatFontSize()
  checkMobile()
  scrollToId()

  scrollAnimate = new ScrollAnimate()
  scrollAnimate.init()

  $(document).on('click', '.js-loading-btn', e => {
    e.preventDefault()
    e.currentTarget.classList.add('is-loading')
  })

  $(document).on('click', '[data-modal-open]', e => {
    e.preventDefault()
    const target = e.target.closest('[data-modal-open]')
    const id = target.getAttribute('data-modal-id')
    const modal = document.querySelector(id)

    if (modal) {
      modal.classList.add('active')
      document.body.classList.add('block-scroll')
    }
  })
  
  $(document).on('click', '[data-modal-close]', e => {
    e.preventDefault()
    const target = e.target
    const modal = target.closest('.js-modal')

    if (modal) {
      modal.classList.remove('active')
      document.body.classList.remove('block-scroll')
    }
  })

  const headerElement = document.querySelector('.js-header')
  if (headerElement) {
    header = new Header(headerElement)
    header.init()
  }

  const textSliderItems = document.querySelectorAll('.js-text-slider')
  if (textSliderItems.length) {
    textSliderItems.forEach((el, i) => {
      textSlider[i] = new TextSlider(el)
      textSlider[i].init()
    })
  }
  
  const servicesSliderItems = document.querySelectorAll('.js-services-slider')
  if (servicesSliderItems.length) {
    servicesSliderItems.forEach((el, i) => {
      servicesSlider[i] = new ServicesSlider(el)
      servicesSlider[i].init()
    })
  }
  
  const stepsSliderItems = document.querySelectorAll('.js-steps-slider')
  if (stepsSliderItems.length) {
    stepsSliderItems.forEach((el, i) => {
      stepsSlider[i] = new StepsSlider(el)
      stepsSlider[i].init()
    })
  }
  
  const fSliderItems = document.querySelectorAll('.js-f-slider')
  if (fSliderItems.length) {
    fSliderItems.forEach((el, i) => {
      fSlider[i] = new FSlider(el)
      fSlider[i].init()
    })
  }
  
  const faqItems = document.querySelectorAll('.js-faq')
  if (faqItems.length) {
    faqItems.forEach((el, i) => {
      faq[i] = new Faq(el)
      faq[i].init()
    })
  }
  
  const inputItems = document.querySelectorAll('.js-input')
  if (inputItems.length) {
    inputItems.forEach((el, i) => {
      inputs[i] = new Input(el)
      inputs[i].init()
    })
  }
  const fplateItems = document.querySelectorAll('.js-f-plate')
  if (fplateItems.length) {
    fplateItems.forEach((el, i) => {
      fplates[i] = new FPlate(el)
      fplates[i].init()
    })
  }

}

function onResize () {
  floatFontSize()
}

function floatFontSize () {
  const mainWidth = 1920
  const maxWidth = document.body.getAttribute('data-max-width') || 2560
  const mainFontSize = 16

  const mScreen = document.querySelector('.m-screen')
  const header = document.querySelector('.header')
  const footer = document.querySelector('.footer')

  let fontSize = null
  
  if (window.innerWidth > 1280) {
    fontSize = (window.innerWidth / mainWidth) * mainFontSize
  } else {
    fontSize = (1280 / mainWidth) * mainFontSize
  }

  if (mScreen) {
    mScreen.style.fontSize = `${fontSize}px`
  }
  
  if (window.innerWidth > maxWidth) {
    fontSize = (maxWidth / mainWidth) * mainFontSize
  }
  
  document.body.style.fontSize = `${fontSize}px`
  
  if (window.innerWidth > 1699) {
    header.style.fontSize = `${fontSize}px`
  } else {
    header.style.fontSize = `${(1699 / mainWidth) * mainFontSize}px`
  }
  
  if (window.innerWidth > 1599) {
    footer.style.fontSize = `${fontSize}px`
  } else {
    footer.style.fontSize = `${(1599 / mainWidth) * mainFontSize}px`
  }
}
