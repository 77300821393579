import Swiper, { Pagination, Autoplay } from "swiper"
Swiper.use([ Pagination, Autoplay ])

export default class TextSlider {

  constructor (el) {
    this.el = el
    this.slider = null
  }

  init () {
    this.slider = new Swiper(this.el, {
      slidesPerView: 1,
      spaceBetween: 40,
      loop: true,
      autoplay: {
        delay: 4000,
        pauseOnMouseEnter: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
  }

}
