import gsap, { Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

export default class Header {
  constructor (el) {
    this.el = el
    this.burger = this.el.querySelector('.js-header-burger')
    this.menu = this.el.querySelector('.js-header-menu')
    this.links = this.el.querySelectorAll('.header__link')
    this.toggleHandler = (e) => this.toggle(e)
    this.scrollHandler = (e) => this.onScroll(e)
    this.isOpen = false
  }

  init () {
    this.initEvents()
  }

  initEvents () {
    this.burger.addEventListener('click', this.toggleHandler)
    window.addEventListener('scroll', this.scrollHandler)
    this.links.forEach(el => {
      if (el.classList.contains('js-scroll-to-id')) {
        el.addEventListener('click', () => {
          if (window.innerWidth < 1280) {
            this.close()
          }
        })
      }
    })
  }

  onScroll (e) {
    if (window.scrollY > 10) {
      this.el.classList.add('collapsed')
    } else {
      this.el.classList.remove('collapsed')
    }
  }

  toggle (e) {
    e.preventDefault()
    
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.isOpen = true
    this.burger.classList.add('active')
    this.el.classList.add('active')
    document.body.classList.add('block-scroll')
    gsap.set(this.menu, { display: 'block' })
    gsap.fromTo(this.menu, 
      {
        opacity: 0,
        visibility: 'hidden',
        y: -20
      },
      {
        duration: 0.6,
        ease: Power3,
        opacity: 1,
        visibility: 'visible',
        y: 0
      }
    )
  }

  close () {
    this.isOpen = false
    this.burger.classList.remove('active')
    this.el.classList.remove('active')
    document.body.classList.remove('block-scroll')
    gsap.fromTo(this.menu, 
      {
        opacity: 1,
        y: 0
      },
      {
        duration: 0.3,
        ease: Power3,
        opacity: 0,
        y: 0,
        onComplete: () => {
          gsap.set(this.menu, { display: 'none' })
        }
      }
    )
  }

}
