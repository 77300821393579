import Swiper, { Navigation } from "swiper"
Swiper.use([ Navigation ])

export default class ServicesSlider {

  constructor (el) {
    this.el = el
    this.slider = null
    this.next = this.el.closest('.services').querySelector('.swiper-navigation__btn--next')
    this.prev = this.el.closest('.services').querySelector('.swiper-navigation__btn--prev')
    this.nextMobile = this.el.querySelector('.swiper-btn--next')
    this.prevMobile = this.el.querySelector('.swiper-btn--prev')
    this.isMobile = false
  }

  init () {
    this.initSlider()
    this.initEvents()
  }

  updateSlider () {
    if (window.innerWidth < 768) {
      if (this.isMobile) return;
      this.isMobile = true
      this.destroySlider()
      this.initSlider()
    } else {
      if (!this.isMobile) return;
      this.isMobile = false
      this.destroySlider()
      this.initSlider()
    }
  }

  initEvents () {
    window.addEventListener('resize', () => this.updateSlider())
  }

  initSlider () {
    this.slider = new Swiper(this.el, {
      slidesPerView: 'auto',
      spaceBetween: 16,
      watchOverflow: true,
      watchSlidesProgress: true,
      centeredSlides: true,
      navigation: {
        prevEl: window.innerWidth < 768 ? this.prevMobile : this.prev,
        nextEl: window.innerWidth < 768 ? this.nextMobile : this.next
      },
      breakpoints: {
        1439: {
          slidesPerView: 4,
          centeredSlides: false,
          spaceBetween: 28
        },
        1025: {
          slidesPerView: 4,
          centeredSlides: false,
          spaceBetween: 28
        },
        768: {
          slidesPerView: 'auto',
          centeredSlides: false,
          spaceBetween: 16
        }
      }
    })
  }

  destroySlider () {
    if (this.slider) {
      this.slider.destroy()
      this.slider = null
    }
  }

}
