import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class FPlate {

  constructor (el) {
    this.el = el
    this.targetSections = document.querySelectorAll('[data-hide-f-plate]')
    this.isHidden = false
  }

  init () {
    this.checkVisibility()
    window.addEventListener('scroll', () => this.checkVisibility())
    window.addEventListener('resize', () => this.checkVisibility())
  }

  checkVisibility () {
    for (let i = 0; i < this.targetSections.length; i++) {
      if ( ScrollTrigger.isInViewport(this.targetSections[i]) ) {
        this.isHidden = true
        break;
      } else {
        this.isHidden = false
      }
    }

    if (this.isHidden) {
      this.el.classList.remove('visible')
    } else {
      this.el.classList.add('visible')
    }
  }

}
